import React from "react";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";

const HistoricPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      {/* Titre principal */}
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Historique Judo
      </h2>
      <DashboardNavigation role={userRole} />

      {/* Contenu principal */}
      <div className="flex flex-col w-full max-w-5xl mx-auto mt-6 text-gray-800 dark:text-gray-300 leading-7">
        {/* Section : Introduction */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">
            Qu'est-ce que le Judo, le Ju-Jitsu, le Ne-Waza, le Taïso ?
          </h3>
          <p>
            Le Judo est un moyen d‘éducation dont le principe de base est
            l’utilisation harmonieuse de l’énergie physique et mentale. Le Judo
            a été inventé par Jigoro Kano.
          </p>
        </section>

        {/* Section : Biographie de Jigoro Kano */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-6">
            À propos de Jigoro Kano
          </h3>
          <div className="flex flex-col lg:flex-row items-center lg:gap-6">
            <img
              src="/img/jigoro_kano.jpg"
              alt="Jigoro Kano"
              className="w-full max-w-md rounded shadow-md mb-6 lg:mb-0"
            />
            <div className="text-justify">
              <p>
                Jigoro Kano est né à Mikage au Japon, le 28 octobre 1860, dans
                une famille de cinq enfants (trois frères et deux sœurs). Il est
                le troisième fils de Jirosaku Mareshiba Kano, un brasseur de
                sake. Il se maria avec Sumako Takezoe, fille de Seisi Takezoe
                qui était ambassadeur du Japon en Corée. Ils eurent neuf
                enfants, six filles et trois garçons.
              </p>
              <p className="mt-4">
                À neuf ans, il perd sa mère. Son père est alors nommé à Tokyo.
                Collégien puis étudiant brillant, il s'intéresse de près à la
                culture occidentale. Jigoro Kano arrive à Tokyo en 1871 où il
                suivra de brillantes études à la faculté des sciences politiques
                et des lettres. N'étant pas doté d'une musculature
                impressionnante, il commença par s'essayer à l'athlétisme, au
                tennis, au baseball, mais il n'y trouva pas ce qu'il
                recherchait. Il fonda cependant le premier club de base-ball du
                Japon.
              </p>
            </div>
          </div>
        </section>

        {/* Section : Fondation du Judo */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">
            Fondation du Judo
          </h3>
          <p>
            Il apprit alors quelques rudiments de Ju-Jitsu, auprès de maître
            Iso, pour résister aux brimades de ses camarades physiquement plus
            forts que lui. Très appliqué, persévérant et soucieux de techniques,
            il maîtrise rapidement plusieurs styles de Ju-Jitsu, notamment le
            Kito-Ryu et le Tenjin-shinyo Ryu qu'il commence à étudier en 1877,
            sous la tutelle de trois maîtres successifs: Hachinosuke Fukuda,
            Masamoto Iso et Tsunetoshi Likubo
          </p>
          <p>
            C'est en 1882 qu'il fonde le Kodokan, Centre pour l'étude de la
            Voie, basé sur les principes des sports modernes dans le temple
            Eishoji à Tokyo. Neuf élèves le fréquentent alors.
          </p>
          Kano désire adapter le Ju-Jitsu à la nouvelle ère. Le Ju-jitsu est une
          technique de combat à mains nues utilisée par les samouraïs sur le
          champ de bataille: sa seule vocation est de mettre, vite et bien, un
          attaquant hors d'état de nuire. Kano cherche à le transformer en un
          moyen d'éducation du corps et de l'esprit " adapté à l'éducation de
          toute une nation "
          <p className="mt-4">
            Il renomme sa discipline de Ju-Jitsu (technique/art de la souplesse)
            à Ju-do (Voie de la souplesse), et fonde ainsi ce qui est
            probablement le premier Budo moderne. Le terme "souplesse" est à
            prendre au sens de "non-résistance" ou "adaptation".
          </p>
          <p className="mt-4">
            Le principe est de ne pas chercher à résister à ce que cherche à
            faire le partenaire / adversaire mais à céder afin d'utiliser sa
            force pour soi. Ce principe aurait été inspiré par l'observation de
            la végétation sous la neige, en constatant que "le chêne casse mais
            le roseau ploie".
          </p>
          <p className="mt-4">
            Kano décède le 4 mai 1938 d'une pneumonie. Il a alors 77 ans et le
            Judo compte déjà plus de 100 000 ceintures noires. Jigorō Kanō fut
            le seul détenteur du grade de 11e dan. Le 12e dan lui fut décerné à
            titre posthume.
          </p>
        </section>

        {/* Section : Cards éducatives */}
        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10 mb-6">
          <div className="bg-gray-300 p-6 rounded shadow-md text-center">
            <p className="text-gray-800">
              Le club de Judo Ju-Jitsu Taïso est le prolongement naturel de la
              famille.
            </p>
          </div>
          <div className="bg-gray-300 p-6 rounded shadow-md text-center">
            <p className="text-gray-800">
              Les dirigeants et les enseignants complètent l’action éducative
              des parents.
            </p>
          </div>
          <div className="bg-gray-300 p-6 rounded shadow-md text-center">
            <p className="text-gray-800">
              Le professeur fait respecter dans le club les éléments de base du
              comportement individuel des judokas résumés dans le code moral.
            </p>
          </div>
        </section>

        {/* Section : Code Moral */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-6">
            Le Code Moral du Judo
          </h3>
          <div className="flex flex-col md:flex-row gap-6 items-center">
            <img
              src="/img/code_moral.png"
              alt="Code Moral du Judo"
              className="w-full max-w-sm rounded shadow-md"
            />
            <ul className="list-disc pl-4">
              <li>
                <strong>La politesse :</strong> Respect d’autrui.
              </li>
              <li>
                <strong>Le courage :</strong> Faire ce qui est juste.
              </li>
              <li>
                <strong>La sincérité :</strong> S’exprimer sans déguiser sa
                pensée.
              </li>
              <li>
                <strong>L’honneur :</strong> Être fidèle à la parole donnée.
              </li>
              <li>
                <strong>La modestie :</strong> Parler de soi sans orgueil.
              </li>
              <li>
                <strong>Le respect :</strong> Base de la confiance.
              </li>
              <li>
                <strong>Le contrôle de soi :</strong> Savoir se taire lorsque la
                colère monte.
              </li>
              <li>
                <strong>L’amitié :</strong> Le plus pur des sentiments humains.
              </li>
            </ul>
          </div>
        </section>

        {/* Section : Conclusion */}
        <section className="text-center italic">
          <p>
            Le Judo, le Ju-Jitsu, le Ne-Waza et le Taïso, c’est un bouquet
            composé de toutes ces valeurs.
          </p>
        </section>
      </div>
    </div>
  );
};

export default HistoricPage;
